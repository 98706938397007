/**
 * GFSelect == Game Factory Select not to reuse the Select name
 */

import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import useStyles from './styles';


function GFSelect(props) {

	const classes = useStyles()

	return (
		<FormControl required className={classes.formLine}>
	        <InputLabel
	            className={classes.inputLabel}
	            classes={{asterisk: classes.inputLabelAsterisk}}
	            >{props.labelText}</InputLabel>
	        <Select
	          classes={{select: classes.inputControl}}
	          name={props.name}
	          value={props.value}
	          onChange={props.onChange}
			  variant="filled"
	        >
	        {props.options.map((item, index) =>
				<MenuItem value={item} key={index}>{item}</MenuItem>
			)}
	        </Select>
			<FormHelperText>{props.hintText}</FormHelperText>
	    </FormControl>
	);
}

GFSelect.propTypes = {
  name: PropTypes.string,
  labelText: PropTypes.string,
  hintText: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default GFSelect;