import "./App.css";
import "./globals.css";
import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import GFApplicationForm, {
  SENDING_NONE,
  SENDING_IN_PROGRESS,
  SENDING_FAILED,
  SENDING_SUCCEEDED,
} from "./GFApplicationForm";
// import {zohoMultiStringToArray} from './components/Utils';
import axios from "axios";

import useStyles from "./styles";

// Duplicates are okay and will be filtered out
import tbFormConfig from "./tbFormConfig";

// import { candidatesData } from "./lib/candidates";

// const countries = require("./commonSettings/countries");
// const skillTree = require("./commonSettings/skillTree");
// const employmentOptions = require("./commonSettings/employmentOptions");
// const countriesList = [...new Set(countries.map((value, key) => Object.values(value)[0]))];

function App() {
  // Possible values: "none", "sending", "success", "fail"
  const [sendingStatus, setSendingStatus] = useState(SENDING_NONE);
  const [errorMessage, setErrorMessage] = useState("");

  // const candidates_orig = candidatesData();
  const recommendedCandidates = [];

  /**
   * Returns a copy of obj where all the fields values are replaced with JSON.strigify version
   * It is helpful for e.g. passing array as a single string
   *
   * @param exceptionFieldList array of field names not to stringify. For example, you wouldn't
   *        want to stringify the File fielss
   */

  function handleSubmitWish(formValuesFromForm, anotherRole = false) {
    console.log("handleSubmitWish:", formValuesFromForm);
    let readyForPosting = formValuesFromForm;

    postForm(readyForPosting, anotherRole);
  }

  /**
   * @param formValuesReadyForPosting means that no resolving is needed, everything's ready for posting
   */
  function postForm(formValues, anotherRole) {
    console.log("postForm");

    /*console.log(candidates_orig);

    let candidatesTempArr = [];

    let recommendedPercent = 60;


    candidates_orig.forEach((candidate) => {
        let candidatePercent = 0;
        if(candidate.category === formValues.category) {
            if(candidate.totalExperienceYears >= formValues.experienceYears){
                candidatePercent += 5;
            } else {
                if (candidate.totalExperienceYears >= formValues.experienceYears - 5) {
                    candidatePercent += formValues.experienceYears - candidate.totalExperienceYears;
                }
            }

            if(candidate.gamesIndustryYears >= formValues.gamesIndustryYears){
                candidatePercent += 5;
            } else {
                if (candidate.gamesIndustryYears >= formValues.gamesIndustryYears - 5) {
                    candidatePercent += formValues.gamesIndustryYears - candidate.gamesIndustryYears;
                }
            }

            if(candidate.country === formValues.country){
                candidatePercent += 5;
            }
            if(candidate.readyToRelocate === formValues.readyToRelocate){
                candidatePercent += 5;
            }

            if(candidate.seniorityLevel === formValues.seniorityLevel){
                candidatePercent += 10;
            }

            if(candidate.expectedMonthlySalary <= formValues.salaryExpectations + 1000 && candidate.expectedMonthlySalary >= formValues.salaryExpectations - 1000){
                candidatePercent += 10;
            }

            var specPercent = 20/formValues.specialities.length;
            var skillPercent = 20/formValues.skills.length;
            var genrePercent = 5/formValues.gameGenres.length;
            var platformPercent = 5/formValues.gamePlatforms.length;
            var enginePercent = 5/formValues.gameEngines.length;
            var artPercent = 5/formValues.artAndAnimationStyles.length;
            var employmentPercent = 10/formValues.employmentOptions.length;
            formValues.specialities.forEach((speciality) => {
                if(candidate.specialitiesList.includes(speciality)) {
                    candidatePercent += specPercent;
                }
            });
            formValues.skills.forEach((skill) => {
                if(candidate.skillList.includes(skill)) {
                    candidatePercent += skillPercent;
                }
            });
            formValues.gameGenres.forEach((genre) => {
                if(candidate.gameGenresList.includes(genre)) {
                    candidatePercent += genrePercent;
                }
            });
            formValues.gamePlatforms.forEach((platform) => {
                if(candidate.gamePlatformsList.includes(platform)) {
                    candidatePercent += platformPercent;
                }
            });
            formValues.gameEngines.forEach((engine) => {
                if(candidate.gameEnginesList.includes(engine)) {
                    candidatePercent += enginePercent;
                }
            });
            formValues.artAndAnimationStyles.forEach((engine) => {
                if(candidate.artAndAnimationStylesList.includes(engine)) {
                    candidatePercent += artPercent;
                }
            });
            formValues.employmentOptions.forEach((type) => {
                if(candidate.employmentTypeList.includes(type)) {
                    candidatePercent += employmentPercent;
                }
            });

            console.log(candidatePercent);
            if(candidatePercent > recommendedPercent) {
                candidatesTempArr.push(candidate);
            }
        }
    })

    console.log(candidatesTempArr);
    setRecommendedCandidates(candidatesTempArr);*/

    /* ALGORITM */

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    let formData = new FormData();
    formData.append("form-name", "personregistration");

    for (const fieldName in formValues) {
      formData.append(fieldName, formValues[fieldName]);
    }
    //
    setSendingStatus(SENDING_IN_PROGRESS);
    setErrorMessage("");
    axios
      .post("/", formData, config)
      .then((res) => {
        if (!anotherRole) {
          setSendingStatus(SENDING_SUCCEEDED);
        } else {
          setSendingStatus(SENDING_NONE);
        }
      })
      .catch((err) => {
        console.log("error posting: ", err);
        if (err.response && err.response.data) {
          console.log("Error that has some data: ", err.response.data);
          setErrorMessage(err.response.data);
        }
        setSendingStatus(SENDING_FAILED);
      });
  }

  const classes = useStyles();

  return (
    <div className="App">
      <Box className={classes.titleBar}>
        <Container className={classes.titleCaptionsContainer}> 
          <Grid
            className={classes.titleCaptionsInnerContainer}
            container
            direction="row"
            alignItems="center"
            justify="center"
          >
            {/* <Grid item>
              <img className={classes.titleLogo} src="logo.png" alt="Game factory talents logo"/>
            </Grid> */}
            <Grid item className={classes.titleLogoContainer}>
              <img
                style={{ marginBottom: "-4px" }}
                className={classes.titleLogo}
                src="board-name-logo.png"
                alt="Game factory talents title logo"
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Container className={classes.formWrapper}>
        <GFApplicationForm
          skillTree={tbFormConfig.skillTree}
          countries={tbFormConfig.countries}
          employmentOptions={tbFormConfig.employmentOptions}
          sendingStatus={sendingStatus}
          setSendingStatus={setSendingStatus}
          errorMessage={errorMessage}
          onSubmitWish={handleSubmitWish}
          recommendedCandidates={recommendedCandidates}
        />
      </Container>
    </div>
  );
}
// onClick={handleFormSubmitBtnClick}

export default App;
