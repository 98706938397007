import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Autocomplete } from '@material-ui/lab';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';

import useStyles from './styles';


function ChippedMultiselect(props) {

  const classes = useStyles()
  
  // When tabulation is pressed or you exit control in ome other ways and some text is entered,
  // generate a fresh chip out of it
  // just as if enter was pressed (except real Enter can possibly send "select-option" reason
  // if user entered the full value manually)
  const handleBlur = event => {
    // console.log("handleBlur, event is ", event);
    if(event.target.value && event.target.value.length > 0) {
      let changedValues = [...props.value];
      changedValues.push(event.target.value);
      props.onChange(event, changedValues, "create-option");
    }
  }

	return (
		<FormControl required={props.required} className={classes.formLine} >
        <InputLabel
            className={classes.inputLabel}
            classes={{asterisk: classes.inputLabelAsterisk}}
            shrink
        >{props.labelText}</InputLabel>
        <Autocomplete
            multiple
            classes={{inputRoot: classes.autocompleteInputRoot}}
            name={props.name}
            options={props.options}
            freeSolo
            forcePopupIcon={true}
            onChange={props.onChange}
            value={props.value}
            onBlur={handleBlur}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  className={classes.chip}
                  classes={{root: classes.chipRoot, colorPrimary: classes.chipColorPrimary}}
                  color="primary"
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => {
              return (
                <TextField {...params} required={props.required && props.value && props.value.length === 0} variant="filled" InputLabelProps={{shrink:true}}/>
              );
            }}
        />
        <FormHelperText>{props.hintText}</FormHelperText>
    </FormControl>
	);
}

ChippedMultiselect.defaultProps = {
  required: true
};

ChippedMultiselect.propTypes = {
  name: PropTypes.string,
  labelText: PropTypes.string,
  hintText: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.arrayOf(PropTypes.string),
  required: PropTypes.bool,
  onChange: PropTypes.func,
};

export default ChippedMultiselect;